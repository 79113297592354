.header {
    margin-bottom: 0px;
}

.subHeader {
    margin-top: 0px;
}

.center {
    text-align: center;
}

.spaceBorder {
    padding: 3%;
}

.begin-to-BIM{
    background-color: #00D89B;
    padding: 10px;
    text-underline-offset: auto;
    color: rgba(0, 0, 0, 0.87);
    text-decoration: none
}