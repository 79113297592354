.cardStyle{
    text-align: center;
    border: #001A4A solid 5px;
    background-color: #fff;
    color: #001A4A;
    padding: 1%;
}


.tableStyle{
    border: #001A4A solid 4px;
}

#backgroundEkko {
    background-image: url(./ekkodale-logo-arrows_transp.png);
    background-repeat: no-repeat; 
    background-size: auto ;
    background-position: center;
    background-clip: inherit;
    display: block;
    margin: auto;
}

.backgroundEkko img{
    background-image: url(./ekkodale-logo-arrows_transp.png);
    background-repeat: no-repeat; 
    background-size: auto ;
    background-position: center;
    background-clip: inherit;
    display: block;
    margin: auto;
}